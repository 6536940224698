import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getAccessToken } from "../authService";
import { PowerbiEmbedConfig } from "../../components/SideNavigation/types";


export const getMyPowerbiDashboard = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<{ data: PowerbiEmbedConfig }> => {
  try{
    const accessToken = await getAccessToken(instance, accounts[0]);
    const bearer = `Bearer ${accessToken}`;
    const response =  await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/powerbi/my-dashboard`,
      headers: {
        accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        authorization: bearer,
      },
    });

    return response.data;
  }catch(error){
    console.error("Error fetching Power BI embed details:", error);
    throw error;
  }
  
};

export const getProjectPowerbiDashboard = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<{ data: PowerbiEmbedConfig }> => {
  try{
    const accessToken = await getAccessToken(instance, accounts[0]);
    const bearer = `Bearer ${accessToken}`;
    const response =  await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/powerbi/project-dashboard`,
      headers: {
        accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        authorization: bearer,
      },
    });

    return response.data;
  }catch(error){
    console.error("Error fetching Power BI embed details:", error);
    throw error;
  }
  
};

export const getAdminPowerbiDashboard = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<{ data: PowerbiEmbedConfig }> => {
  try{
    const accessToken = await getAccessToken(instance, accounts[0]);
    const bearer = `Bearer ${accessToken}`;
    const response =  await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/powerbi/delivery-dashboard`,
      headers: {
        accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        authorization: bearer,
      },
    });

    return response.data;
  }catch(error){
    console.error("Error fetching Power BI embed details:", error);
    throw error;
  }
  
};



