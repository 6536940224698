import { useEffect, useState } from "react";
import SideNavigationComponent from "../../components/SideNavigation";

import ControlCenter from "../../assets/svg/ControlsCenter.svg";
import Inbox from "../../assets/svg/Inbox.svg";
import { useUserContext } from "../../contexts/user.context";
import { getNotificationCount } from "../../services/notificationService/notificationService";
import { getAccessToken } from "../../services/authService";
import { useMsal } from "@azure/msal-react";
import { useNotificationsContext } from "../../contexts/notifications.context";
import { updateViewsAsync, useViews } from "../../contexts/view.context";

const SideNavigation = () => {
  const { instance, accounts } = useMsal();
  const { dispatch, state } = useViews();
  const [collapsed, setCollapsed] = useState(false);
  const [dashboards, setDashboards] = useState({
    data: [
      {
        name: "My Notifications",
        icon: Inbox,
        notifications: 0,
        route: "/my-notifications",
      },
      {
        name: "Control Center",
        icon: ControlCenter,
        notifications: 0,
        route: "/control-center",
      }, //always set this as last object
    ],
    name: "Dashboards",
  });

  const powerbiUserDashboards = {
    data: [
      // {
      //   name: "My Dashboard",
      //   icon: ControlCenter,
      //   route: "/my-dashboard",
      // },
      {
        name: "Project Dashboard",
        icon: ControlCenter,
        route: "/project-dashboard",
      },
    ],
    name: "Powerbi User Dashboards",
  };

  const powerbiAdminDashboards = {
    data: [
      {
        name: "Delivery Dashboard",
        icon: ControlCenter,
        route: "/delivery-dashboard",
      },
    ],
    name: "Powerbi Admin Dashboards",
  };

  useEffect(() => {
    updateViewsAsync(dispatch, null, instance, accounts)
  }, [])



  const onClickCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const { details, status, image, fetchUserDetails, fetchUserImage } =
    useUserContext();

  const { count, notificationCount } = useNotificationsContext();

  useEffect(() => {
    fetchUserDetails();
    fetchUserImage();
    notificationCount();
  }, []);

  useEffect(() => {
    fetchNotificationCount();
  }, [count]);

  const fetchNotificationCount = async () => {
    var newDashboards = { ...dashboards };
    newDashboards.data = dashboards.data.map((element) => {
      if (element.route === "/my-notifications") {
        return { ...element, notifications: count };
      }
      return element;
    });
    setDashboards(newDashboards);
  };

  //TODO: When APIs are implemented need to remove the hard coded values in the below section.
  // User details API is working. In config.json make "REACT_APP_IS_AXIOS_MOCK" false and check the functionality for now

  return (
    <SideNavigationComponent
      collapsed={collapsed}
      onClickCollapsed={onClickCollapsed}
      privateFilters={state.privateViewState}
      publicFilters={state.publicViewState}
      dashboards={dashboards}
      ProjectName="Trackly"
      name={details.displayName}
      position={details.mail}
      avatarURL={image} 
      powerbiUserDashboards={powerbiUserDashboards}
      powerbiAdminDashboards={powerbiAdminDashboards}
    />
  );
};

export default SideNavigation;
